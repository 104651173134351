import { LazycatAccountCenter } from '@lazycatcloud/public-account'

import { setupCache } from 'axios-cache-interceptor' /*/dev'*/

import { $$devSiteMode } from '@/utils/env'
import { AxiosRequestConfig, default as _axios, type AxiosError, type AxiosResponse } from 'axios'

import { loginServer } from '@/api/login'
import { SimpleToast } from '@comp/Toast'

const kMatchNeedAuth = ['/comment/like', '/comment/unlike']
const appstoreCms = 'https://appstore.api.lazycat.cloud'
export const axios = setupCache(
  _axios.create({
    baseURL: `${appstoreCms}/api`,
    withCredentials: true,
  }),
  {
    debug: console.log,
  }
)

function login() {
  if ($$devSiteMode) {
    window.$wujie.bus.$emit('login')
  } else {
    window.parent.open(`https://${loginServer}?from=${encodeURIComponent(window.location.href)}`, '_self')
  }
}

function isInApplication(): boolean {
  return navigator.userAgent.indexOf('Lazycat_Client') != -1
}

axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.interceptors.request.use(async (req: AxiosRequestConfig) => {
  if (kMatchNeedAuth.includes(req.url ?? '') || (req.url && /^\/comment\/[^\/]+\..+$/.test(req.url)) /* pkgID 必须为 io.xx.d1y 这种格式 */) {
    const token = await LazycatAccountCenter.getToken()
    if (!token) {
      if ($$devSiteMode) {
        window.$wujie.bus.$emit('login')
        return req
      } else {
        const accountServer = new LazycatAccountCenter()
        await accountServer.syncToken()
      }
    }
    if (isInApplication()) {
      document.cookie = `userToken=${token};`
    }
    req.headers!['X-User-Token'] = token
  }
  return req
})

axios.interceptors.response.use(
  function (response: AxiosResponse) {
    return response
  },
  function (error: AxiosError) {
    console.error('[api error]', error)
    if (error.response?.status == 502) {
      console.log('502 ', error)
      SimpleToast({
        value: `接口请求502(${error.response.config.url})`,
      })
    }
    // 如果是401为未登录状态，统一跳转到登录界面
    if (error.response?.status == 401) {
      login()
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)
